import './app.scss';
import domready from 'domready';
import 'lazysizes';
import { getOffset, smoothScrollTo } from './utilities/js/helper';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { EventScroller } from './utilities/js/event-scroller';
import { MediaMatchHandler } from './utilities/js/media-match-handler';
import SiteHeader from './layout/siteheader/siteheader';
import Navigation from './layout/navigation/navigation';
import ContactBar from './components/contact-bar/contact-bar';

// GLOBAL OPTIONS
window.OPTIONS = { // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    },
    loader: '<b class="loading"><b class="loading__loader"><i>loading...</i></b></b>'
};

window.eventScroller = new EventScroller();
window.mediaMatchHandler = new MediaMatchHandler();

window.youtubeApi = false;
window.youtubeApiLoading = false;

window.checkInview = () => {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('inview');
                observer.unobserve(entry.target);
            }
        });
    }, { threshold: 0.1 });

    const $inviews = document.querySelectorAll('[data-inview]:not(.inview)');
    $inviews.forEach((target) => {
        observer.observe(target);
    });
};

const handleUsercentrics = () => {
    const maxTries = 100;
    let currentTry = 0;

    const addStyleTag = ($host) => {
        const $shadowRoot = $host.shadowRoot;
        $host.style.position = 'relative';
        $host.style.zIndex = '1';
        const $styleTag = document.createElement('style');
        let styleInformation = '';
        styleInformation = styleInformation + '\n.liwSgQ { min-height: auto; border-radius: 8px 8px 0 0; box-shadow: rgb(0 0 0 / 22%) 0px 5px 26px 0px, rgb(0 0 0 / 30%) 0px 20px 28px 0px; }';
        styleInformation = styleInformation + '\n[data-testid="uc-save-button"],[data-testid="uc-more-button"],[data-testid="uc-deny-all-button"],[data-testid="uc-accept-all-button"] { font-family: "Garage Gothic", Arial, sans-serif !important; height: auto; font-size: 22px; font-weight: 700; line-height: 1; padding: 11px 18px; letter-spacing: 0.1em; text-transform: uppercase; }';
        $styleTag.innerHTML = styleInformation;

        $shadowRoot.appendChild($styleTag);
    };

    const userCentricsInterval = window.setInterval(() => {
        const $usercentrics = document.getElementById('usercentrics-root');
        if ($usercentrics || currentTry === maxTries) {
            clearInterval(userCentricsInterval);
            if ($usercentrics) {
                addStyleTag($usercentrics);
            }
        }
        currentTry = currentTry + 1;
    }, 100);
};

const initContent = ($content) => {
    console.log('start app');

    const $$anchor = $content.querySelectorAll('a[href*="#"]');
    for (let i = 0; i < $$anchor.length; i++) {
        const $anchor = $$anchor[i];

        $anchor.addEventListener('click', (e) => {
            let baseUrl = $anchor.getAttribute('href').split('#')[0];
            const targetId = $anchor.getAttribute('href').split('#')[1];
            const $target = document.getElementById(targetId);
            const currentUrl = window.location.href;

            if (baseUrl === '') {
                baseUrl = currentUrl;
            }

            if (baseUrl === currentUrl && $target !== null) {
                const anchorDimensions = getOffset($target);
                const anchorDimensionsTop = anchorDimensions.top;

                smoothScrollTo(0, anchorDimensionsTop, window.OPTIONS.speed);
                e.preventDefault();
            }
        });
    }

    if (typeof window.Modernizr !== 'undefined') {
        if (!window.Modernizr.csshover) {
            const $hovers = $content.querySelectorAll('[data-hover]');
            const hoverClass = 'hover';

            if ($hovers.length > 0) {
                for (let i = 0; i < $hovers.length; i++) {
                    const $hover = $hovers[i];

                    $hover.addEventListener('touchstart', () => {
                        if (!$hover.classList.contains(hoverClass)) {
                            $hover.classList.add(hoverClass);
                        }
                    }, { passive: true });
                }

                document.addEventListener('touchstart', (e) => {
                    const $hoveredElements = $content.getElementsByClassName(hoverClass);

                    for (let i = 0; i < $hoveredElements.length; i++) {
                        const $currentHover = $hoveredElements[i];
                        const isClickInside = $currentHover.contains(e.target);

                        if (!isClickInside) {
                            if ($currentHover.classList.contains(hoverClass)) {
                                $currentHover.classList.remove(hoverClass);
                            }
                        }
                    }
                }, { passive: true });
            }
        }
    }

    loadLazyImages();
};

domready(function () {
    const initApplication = () => {
        initContent(document);

        handleUsercentrics();

        window.eventScroller.init();

        const $siteheader = document.querySelector('[data-siteheader="root"]');
        if ($siteheader) {
            const siteheaderAPI = new SiteHeader($siteheader);
            $siteheader.API = siteheaderAPI;
        }

        const $navigation = document.querySelector('[data-nav="root"]');
        if ($navigation) {
            const navigationAPI = new Navigation($navigation);
            $navigation.API = navigationAPI;
        }

        const $contactBar = document.querySelector('[data-contactbar="root"]');
        if ($contactBar) {
            const contactBarAPI = new ContactBar($contactBar);
            $contactBar.API = contactBarAPI;
        }

        window.setTimeout(() => {
            window.checkInview();
        }, 750);

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });

        window.setTimeout(() => {
            window.dispatchEvent(contentUpdateEvent);
        }, 500);

        document.body.classList.add('page--loaded');

        window.addEventListener('beforeunload', () => {
            const isProtocolLink = document.activeElement.protocol === 'mailto:' || document.activeElement.protocol === 'tel:';

            if (!isProtocolLink) {
                document.body.classList.remove('page--loaded');
            }
        });

        window.addEventListener('pageshow', (event) => {
            const historyTraversal = event.persisted || (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);
            if (historyTraversal) {
                window.location.reload();
            }
        });
    };

    initApplication();
});
