class SiteHeader {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-siteheader',
            toggleAttr: 'toggle',
            scrollOffset: 40
        };

        this.settings = Object.assign({}, defaults, options);
        this.$siteHeader = $element;
        this.$siteHeaderToggles = this.$siteHeader.querySelectorAll(`[${this.settings.initAttr}="${this.settings.toggleAttr}"]`);
        this.$searchInput = this.$siteHeader.querySelector('[data-search-bar-input]');
        this.eventScroller = window.eventScroller;
        this.currentScrollPosition = window.scrollY;
        this.backScrolling = false;
        this.activeState = false;

        window.setTimeout(() => {
            this.initialize();
        }, 100);
    }

    initialize () {
        this.checkPagePosition();
        this.eventScroller.customFunctions.push(() => {
            this.checkPagePosition();
        });

        this.initToggles();
    }

    initToggles () {
        this.$siteHeaderToggles.forEach($toggle => {
            $toggle.addEventListener('change', () => {
                this.unCheckToggles($toggle);
                this.activeState = !this.activeState;
                if (this.activeState) {
                    this.$searchInput.focus();
                } else {
                    this.$searchInput.blur();
                }
            });
        });
    }

    checkPagePosition () {
        if (window.scrollY > this.settings.scrollOffset) {
            this.$siteHeader.classList.add('siteheader--sticky');

            if (window.scrollDirection === 'up') {
                if (this.backScrolling === false) {
                    this.currentScrollPosition = window.scrollPosition;
                    this.backScrolling = true;
                }

                if (!this.$siteHeader.classList.contains('siteheader--up') && window.scrollPosition <= this.currentScrollPosition - this.settings.scrollOffset) {
                    this.$siteHeader.classList.add('siteheader--up');
                }
            } else {
                if (this.$siteHeader.classList.contains('siteheader--up')) {
                    this.$siteHeader.classList.remove('siteheader--up');
                    this.backScrolling = false;
                }
            }
        } else {
            this.$siteHeader.classList.remove('siteheader--sticky');
            this.$siteHeader.classList.remove('siteheader--up');
        }
    }

    unCheckToggles ($activeToggle) {
        this.$siteHeaderToggles.forEach($toggle => {
            if ($toggle !== $activeToggle) {
                $toggle.checked = false;
            }
        });
    }
}

export default SiteHeader;
